import { createContext, useEffect, useRef } from "react";

type TVisibilityChangeCallback = (visible: boolean) => void

interface IContext {
    observe: (target: Element, onVisibilityChange: TVisibilityChangeCallback) => void
    unobserve: (target: Element) => void
}

export const IntersectionObserverContext = createContext<IContext | null>(null);

interface Props {
    disabled?: boolean;
    options?: IntersectionObserverInit;
    children: React.ReactNode;
}
export function IntersectionObserverProvider({ disabled, options, children }: Props) {
    const handler = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(e => {
            const visible = typeof e.isIntersecting === 'undefined'
                ? e.intersectionRatio > 0
                : e.isIntersecting;

            observableHandlers.current.get(e.target.id)?.(visible);
        });
    }

    const observerRef = useRef<IntersectionObserver>(new IntersectionObserver(handler, options));
    const observableHandlers = useRef(new Map<string, TVisibilityChangeCallback>());

    useEffect(() => {
        const observer = observerRef.current;
        return () => {
            observer?.disconnect();
        }
    }, [])

    if(disabled) return <>{children}</>

    const observe = (target: Element, onVisibilityChange: TVisibilityChangeCallback) => {
        if (!target.id) throw new Error("element.id is required");

        observableHandlers.current.set(target.id, onVisibilityChange);
        observerRef.current.observe(target)
    }

    const unobserve = (target: Element) => {
        if (!observerRef.current) return;

        observableHandlers.current.delete(target.id);
        observerRef.current.unobserve(target);
    }

    return (
        <IntersectionObserverContext.Provider value={{ observe, unobserve }}>
            {children}
        </IntersectionObserverContext.Provider>
    )
}