import { useDispatch } from "react-redux";
import { OriginatingTransactionDocument } from "../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { EmptyPlaceholder } from "../../../../common";
import { DownloadedDocument } from "../../../../../types/amr-pipeline/models/AnalyticsSession";
import DownloadDocument from "../../../../amrPipeline/common/documents/DownloadDocument";
import { cloManagersActions } from "../../../../../actions/clo-managers.actions";

interface Props {
    companyReferenceName: string;
    documents: DownloadedDocument[];
}

export function PresDownloadedTab({ companyReferenceName, documents }: Props) {
    const dispatch = useDispatch();
    const getDocumentDownloadLink = (document: DownloadedDocument) =>
        dispatch(
            cloManagersActions.downloadManagerPresentation(
                companyReferenceName,
                document
            )
        );

    if (!documents.length) {
        return <EmptyPlaceholder text="There is no information to display." />;
    }

    return (
        <ul className="list-bullet docs-downloaded-box">
            {documents.map((d, index) => (
                <li
                    key={d.referenceName + index}
                    className="download-document-item"
                >
                    {d.isDeleted ? (
                        d.name
                    ) : (
                        <DownloadDocument
                            document={
                                {
                                    referenceName: d.referenceName,
                                    name: d.name,
                                } as OriginatingTransactionDocument
                            }
                            onClick={() => getDocumentDownloadLink(d)}
                            secondary
                        >
                            {d.name}
                        </DownloadDocument>
                    )}
                </li>
            ))}
        </ul>
    );
}
