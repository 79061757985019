import { cloManagersActions } from '../../../../actions/clo-managers.actions';
import { useDispatch } from 'react-redux';
import { DealSecurity } from '../../../../types/security/DealSecurityStatistics';
import { BwicRulesButton } from '../../../bwic-rules/BwicRulesButtons';

interface Props {
    security: DealSecurity;
    disabled?: boolean;
}

export const DealsBwicDetailsButton = ({ security, disabled = false }: Props) => {
    const dispatch = useDispatch();

    const handleClick = (visible: boolean) => {
        if (visible) {
            dispatch(cloManagersActions.selectedSecurityChange(security));
        } else {
            dispatch(cloManagersActions.selectedSecurityChange());
        }
    };

    return (
        <BwicRulesButton
            id={String(security.securityId)}
            bidsDueUtc={security.currentBwic?.bidsDueUtc}
            disabled={disabled}
            onClick={handleClick}
        />
    );
};
