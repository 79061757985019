import cn from 'classnames';
import { useAppSelector } from "../../effects/useAppSelector";
import { dateTimeUtils } from "../../utils";
import { Relative } from "../common/Relative";
import { rulesActions } from '../../actions/rules.actions';
import { useAppDispatch } from '../../effects/useAppDispatch';

interface Props {
    id: string;
    bidsDueUtc?: Date;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: (visible: boolean) => void;
}
export function BwicRulesButton({ id, bidsDueUtc, children, disabled = false, onClick }: Props) {
    const dispatch = useAppDispatch();
    const visible: boolean = useAppSelector(s => s.rules.show);
    const stateId: string | undefined = useAppSelector(s => s.rules.positionId);
    const isActive = visible && id === stateId;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isActive) {
            onClick?.(false);
            dispatch(rulesActions.hide());
        } else {
            onClick?.(true);
            dispatch(rulesActions.show(id));
        }
    }

    return (
        <div className="current-bwic-info flex-row">
            <Relative className="vertical-align-top" inline>
                <button
                    className={cn('pseudo-link bwic-date', { 'is-active': isActive })}
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {!!bidsDueUtc && dateTimeUtils.utcToLocalString(bidsDueUtc)}
                    {!bidsDueUtc && Boolean(children) && children}
                </button>
            </Relative>
        </div>
    );
}
