import * as React from 'react';
import { ReactNode } from 'react';
import cn from "classnames";

interface Props {
    title?: string;
    text?: ReactNode;
    children?: ReactNode;
    textView?: boolean;
    textViewRow?: boolean;
    className?: string;
}

export const EmptyPlaceholder = ({ title = '', text = "No search results found.", children = null, textView = false, textViewRow = false, className }: Props) => (
    <div className={cn('empty-placeholder', className, { 'text-view': textView, 'text-view-row': textViewRow })}>
        {!!title && <h2>{title}</h2>}
        {!!text && <p>{text}</p>}
        {children}
    </div>
);
