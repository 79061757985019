import { useEffect, useState, useMemo  } from "react";
import { EmptyPlaceholder } from "../common";
import classnames from "classnames";
import { SelectionPanelSearch } from '../amrPipeline/common/SelectionPanelSearch';
import { CloManagersNavItem } from './CloManagersNavItem';
import { PipelineTabs, Tab } from '../amrPipeline/aggregated/PipelineTabs';
import { CloManager } from '../../types/clo-managers/CloManager';
import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../types/state/AppState";
import { UserConfigState } from "../../types/state/UserConfigState";
import { AlertOption } from "../../types/amr-pipeline/enums/AlertOption";
import { getAlertValues, isCollateralManagersOwnCompany } from "../../utils/amr-pipeline.utils";

enum SearchTab {
    All = 'All',
    Favourite = 'Favorite'
}

interface Props {
    data: CloManager[];
    selectedReferenceName: string;
    onChange: (referenceName: string) => void;
    onFavoriteClick: (referenceName: string, favorite: boolean) => void;
}

const searchManagers = (cloManagers: CloManager[], searchTerm: string) => {
    return cloManagers.filter(({ legalName }) => legalName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
};

const getFavorites = (cloManagers: CloManager[]) => {
    return cloManagers.filter(({ favorite }) => favorite);
}

export const CloManagersSelectionPanel = ({
    data,
    selectedReferenceName,
    onChange,
    onFavoriteClick,
}: Props) => {
    const [collapsed, setCollapsed] = useState(false);
    const [tab, setTab] = useState(SearchTab.All);
    const [tabSearchTerm, setTabSearchTerm] = useState({
        [SearchTab.All]: '',
        [SearchTab.Favourite]: '',
    });

    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const alertConfigs = useSelector<AppState, UserConfigState>((state: AppState) => state.imUserConfig);

    const cloManagerAnalyticsConfig = getAlertValues(alertConfigs.cloManagerAnalyticsAlert.value);
    const cloManagerImConfig = getAlertValues(alertConfigs.cloManagerIssuanceMonitorAlert.value);
    const cloManagerApConfig = getAlertValues(alertConfigs.cloManagerArrangerPipelineAlert.value);
    const cloManagerBwicConfig = getAlertValues(alertConfigs.cloManagerBwicAlert.value);
    const cloManagerDealerInventoryConfig = getAlertValues(alertConfigs.cloManagerDealerInventoryAlert.value);

    const selectedManagerRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        var customEvent = document.createEvent("Events");
        customEvent.initEvent("resize", true, true);
        window.dispatchEvent(customEvent);
    }, [collapsed]);

    useEffect(() => {
        if (selectedReferenceName) {
            selectedManagerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const handleSearch = (search: string) => {
        setTabSearchTerm(prevValue => ({
            ...prevValue,
            [tab]: search,
        }));
    };

    const getManagers = () => {
        const searchTerm = tabSearchTerm[tab];

        if (tab === SearchTab.All) {
            return searchManagers(data, searchTerm);
        }

        return searchManagers(getFavorites(data), searchTerm);
    }

    const getEmptyText = () => {
        const searchTerm = tabSearchTerm[tab];

        if (searchTerm || tab === SearchTab.All) {
            return undefined;
        }

        return 'There are no favorite managers yet.';
    };

    const favoritesNumber = useMemo(
        () => searchManagers(getFavorites(data), "").length,
    [data]);

    const cloManagers = getManagers();

    const isEmpty = !cloManagers.length;

    return (
        <aside className={classnames("sidebar sidebar-nav sidebar-nav-clo-managers", { collapsed })}>
            <SelectionPanelSearch
                searchTerm={tabSearchTerm[tab]}
                collapsed={collapsed}
                placeholder="Search by manager"
                onCollapseClick={setCollapsed}
                onSearch={handleSearch}
            />
                <PipelineTabs value={tab} onChange={setTab} className="tabs-left-shift-sm">
                    <Tab title={SearchTab.All} key={SearchTab.All} value={SearchTab.All} />
                    <Tab
                        key={SearchTab.Favourite}
                        title={
                            <>
                                <span>{SearchTab.Favourite}</span>
                                {!!favoritesNumber && <span className="text-warm-grey">{` (${favoritesNumber})`}</span>}
                            </>
                        }
                        value={SearchTab.Favourite}
                    />
                </PipelineTabs>
            <nav className="sidebar-nav-list">
                {isEmpty && <EmptyPlaceholder textView text={getEmptyText()} />}
                {cloManagers.map(cloManager => {
                    const { referenceName } = cloManager;

                    const isOwnCompany = isCollateralManagersOwnCompany(cloManager, userCompany);

                    const selectedCloManagerAnalyticsAlert = cloManagerAnalyticsConfig.get(referenceName)?.alertOption;
                    const imAlertOption = cloManagerImConfig.get(referenceName)?.alertOption;
                    const apAlertOption = cloManagerApConfig.get(referenceName)?.alertOption;
                    const bwicAlertOption = cloManagerBwicConfig.get(referenceName)?.alertOption;
                    const dealerInventoryAlertOption = cloManagerDealerInventoryConfig.get(referenceName)?.alertOption;

                    const withAlert = [
                        isOwnCompany ? selectedCloManagerAnalyticsAlert : AlertOption.Never,
                        imAlertOption,
                        apAlertOption,
                        bwicAlertOption,
                        dealerInventoryAlertOption,
                    ].some(alert => alert && alert !== AlertOption.Never);

                    return (
                        <CloManagersNavItem
                            ref={referenceName === selectedReferenceName ? selectedManagerRef : undefined}
                            key={referenceName}
                            searchTerm={tabSearchTerm[tab]}
                            selected={selectedReferenceName === referenceName}
                            cloManager={cloManager}
                            onClick={onChange}
                            withAlert={withAlert}
                            onFavoriteClick={onFavoriteClick}
                        />
                    );
                })}
                </nav>
        </aside>
    );
};
