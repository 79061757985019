import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../types/state/AppState";
import { CloManagersState } from "../../../../types/state/CloManagersState";
import { SectionContent } from "../../../amrPipeline/common/section/SectionContent";
import { FormField } from "../../../amrPipeline/common/FormField";
import { AmrFile } from "../../../../types/amr-pipeline/models/AmrFile";
import DownloadDocument from "../../../amrPipeline/common/documents/DownloadDocument";
import { cloManagersActions } from "../../../../actions/clo-managers.actions";
import { FileIcon } from "../../../controls";
import { ShowMore } from "../../../amrPipeline/common/ShowMore";
import { compareDatesDesc } from "../../../../utils/compare.utils";
import { RequiredFeature } from '../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../types/billing/SubscriptionFeature';
import { useContext } from 'react';
import CloManagersContext from '../../cloManagersContext';
import { EmptyPlaceholder } from "../../../common";
import { BlockedFeatureContent, SubscribeLink } from '../../../access/BlockedFeatureText';

export const ManagerPresentations = () => {
    const dispatch = useDispatch();
    const { ownCompany } = useContext(CloManagersContext);
    const { selectedManager } = useSelector<AppState, CloManagersState>(
        (state: AppState) => state.cloManagers
    );

    if (!selectedManager?.details) {
        return null;
    }

    const { details } = selectedManager;
    const managerPresentationFiles = details?.managerPresentationFiles?.sort(
        (a, b) => compareDatesDesc(b.uploadTime, a.uploadTime)
    );
    const withManagerPresentations = !!managerPresentationFiles?.length;

    const renderFileList = (files: AmrFile[]) =>
        files.map((file) => (
            <div className="document-item">
                <DownloadDocument
                    onClick={() =>
                        dispatch(
                            cloManagersActions.downloadManagerPresentation(
                                selectedManager.referenceName,
                                file
                            )
                        )
                    }
                    document={file}
                    secondary
                >
                    <FileIcon filename={file.name} />
                    <span>{file.name}</span>
                </DownloadDocument>
            </div>
        ));

    const managerPresentationFilesList = (files: AmrFile[]) => {
        if (files?.length < 4) {
            return renderFileList(files);
        }

        return (
            <ShowMore
                renderMore={() => renderFileList(files)}
                renderLess={() =>
                    renderFileList(files.slice(0, 3))
                }
                className="btn-link show-more-documents"
            />
        );
    };

    const renderManagerPresentation = () => {
        return (
            <RequiredFeature
                feature={SubscriptionFeature.ManagerProfilePresentations}
                overrideRequiredFeatures={ownCompany || !details.numberOfManagerPresentationFiles}
                renderBlockedContent={() => (
                    <FormField title="">
                        <BlockedFeatureContent
                            inline
                            className="empty-placeholder empty-placeholder-col-full"
                            text={<><SubscribeLink /> to see Manager Presentations.</>}
                        />
                    </FormField>
                )}
            >
                {withManagerPresentations
                    ? <FormField title="">{managerPresentationFilesList(managerPresentationFiles)}</FormField>
                    : <EmptyPlaceholder text="There is no information to display." />
                }
            </RequiredFeature>
        );
    };

    return (
        <SectionContent
            title="Manager Presentations"
            className="data-item-general-information"
        >
            {renderManagerPresentation()}
        </SectionContent>
    );
};
